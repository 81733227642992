
/*  ---- header ---- */

.header {
    width: 100%;
    position: fixed;
    z-index: 101;
    top: 0;
    min-width: 320px;

    &__top-side {
        min-height: 40px;
        background-color: #000;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-family: 'PT Sans', sans-serif;
        @include display(flex);
        width: 100%;
        padding: 0 20px;

        @media only screen and (max-width: 991px) {
            font-size: 12px;
        }

        @media only screen and (min-width: 460px) {
            white-space: nowrap;
        }
    }

    &__bottom-side {
        padding: 4px 15px 10px 25px;
        background: #fff;

        @media only screen and (max-width: 767px) {
            padding: 8px 15px 8px 25px;
        }
    }

    &-basket {
        display: inline-block;
        float: right;
        line-height: 1.2;
        text-align: right;
        font-size: 12px;

        @media only screen and (min-width: 767px) {
            padding-top: 7px;
        }

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        &__text {
            margin-right: 15px;
            color: #5c5c5c;

            @media only screen and (max-width: 459px) {
                display: none;
            }
        }
    }

    &-basket__icon {
        background: url('../media/icons-shopping-cart.png') no-repeat;
        width: 36px;
        height: 33px;
        color: #fff;
        line-height: 1.2;
        text-align: center;
        padding-top: 9px;
        padding-left: 5px;

        &:hover, &:focus {
            color: rgba(255,255,255,0.8);
        }
    }

    &-tel, &-tel:hover, &-tel:focus {
        color: #ffffff;
    }
}

.header__top-side span {
    width: 100%;
    margin: auto;
    line-height: 1.2;

    &:first-child {
        @media only screen and (max-width: 640px) {
            display: none;
        }
    }

    @media only screen and (max-width: 459px) {
        font-size: 10px;
        width: 60%;
    }

    &:last-child {
        text-align: right;

        @media only screen and (max-width: 459px) {
            width: 40%;
        }
    }
}

/* ---- hamburger ---- */

.hamburger {
    padding: 0px 0px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 0px;
    margin-right: 60px;
    vertical-align: center;

    @media only screen and (max-width: 459px) {
        margin-right: 25px;
    }

    &-box {
        width: 32px;
        height: 32px;
        display: inline-block;
        position: relative;

        @media only screen and (max-width: 767px) {
            height: 23px;
        }

        @media only screen and (max-width: 459px) {
            height: 23px;
            width: 25px;
        }

        .is-active & {
            height: 18px;

            @media only screen and (max-width: 459px) {
                height: 23px;
            }
        }
    }
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -5px;

    .is-active & {
        @media only screen and (max-width: 459px) {
            margin-top: 0;
        }
    }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 32px;
    height: 4px;
    background-color: #190028;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -13px;
    display: none;
}

.hamburger-inner::after {
    bottom: -17px;

    @media only screen and (max-width: 767px) {
        bottom: -11px;
    }
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}

.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    /* IE 9 */
    -webkit-transform: rotate(45deg);
    /* Chrome, Safari, Opera */
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    background-color: #190028;
}

.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease;
    background-color: #190028;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    /* IE 9 */
    -webkit-transform: rotate(-90deg);
    /* Chrome, Safari, Opera */
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    background-color: #190028;
}

/* ---- logo ---- */

.site-logo {
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
    color: #000;
    line-height: 1.2;
    transition: none;

    &:hover {
        color: rgba(0,0,0,0.8);
    }

    @media only screen and (max-width: 767px) {
        font-size: 25px;
    }

    @media only screen and (max-width: 459px) {
        font-size: 24px;
    }
}

.site-logo span {
    font-size: 24px;

    @media only screen and (max-width: 459px) {
        font-size: 14px;
    }
}

/*  ---- sidebar ---- */

.sidebar {
    position: absolute;
    background-color: #fff;
    left: 0;
    z-index: 100;
    height: 100vh;
    width: 83px;
    text-align: center;
    top: 0;
}

.sidebar span {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    display: block;
    position: absolute;
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    /* IE 9 */
    -webkit-transform: rotate(-90deg);
    /* Chrome, Safari, Opera */
    left: 0;
    top: 50%;
    width: 100%;
    margin-top: -18px;
    font-size: 16px;
}

/* ---- footer ---- */

.footer {
    background: transparent;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative;
    z-index: 101;

    @media only screen and (min-width: 1201px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
        @include flex-wrap(wrap);
    }

    &__contact {

        &-block {
            padding: 22px 22px 100px;
            background: #051831 url(./../media/footer-coconut.png) no-repeat;
            min-height: 300px;
            width: 100%;
            display: flex;

            p {
                line-height: normal;
                margin: 0;
            }

            *, *:hover, *:focus {
                color: #fff;
            }

            @media only screen and (min-width: 768px) {
                width: calc(50% + 15px);
            }

            @media only screen and (max-width: 460px) {
                @include align-items(baseline);
                flex-wrap: wrap;
            }
        }

        &-data {
            width: 50%;
            padding: 20px 10px 20px 45px;
            border-left: 1px solid #fff;

            @media only screen and (max-width: 460px) {
                width: 100%;
                border-top: 1px solid #fff;
                border-left: 0;
                padding: 115px 10px 0 0;
            }
        }

        &-address {
            margin-bottom: 15px;
        }
    }

    &-link-list {
        width: 50%;
        text-transform: uppercase;
        padding-left: 65px;
        margin-bottom: 40px;
        padding-top: 25px;

        li {
            margin-bottom: 15px;
        }

        @media only screen and (max-width: 460px) {
            width: 100%;
            padding-left: 0;
            padding-top: 0;
            margin-bottom: 10px;
        }
    }

    &__feedback {
        padding: 22px 22px 80px;
        background: #d9d5d4;
        width: 100%;

        @media only screen and (min-width: 768px) {
            width: calc(50% - 15px);
        }
    }
}

.footer__feedback-form {
    margin: 0 auto;
    max-width: 363px;

    &-title {
        text-transform: uppercase;
        text-align: center;
    }

    &-field {
        @include display(flex);
        margin: 15px 0 10px;
    }

    &-input {
        width: 100%;
        margin-top: 10px;
        padding: 4px 10px;

        &:first-child {
            margin-top: 0;
        }

        &-block {
            width: 50%;
            padding-right: 15px;
        }
    }

    &-textarea {
        width: 100%;
        height: 100%;
        border: none;
        padding: 4px 10px;

        &-block {
            width: 50%;
            padding-left: 15px;
        }
    }

    &-submit {
        background: #3f4c6b;
        padding: 7px;
        width: 100%;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        border: none;
        outline: none;

        &:hover{
            padding: 4px;
        }

        @media only screen and (min-width: 1201px) {
            font-size: 14px;
        }
    }
}
