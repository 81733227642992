.menu-group{
	display: none;
	width: 50%;
	margin-top: 97px;
	background: #14253d;
	z-index: 102;
	height: 100%;
	min-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	margin-left: 83px;
	position: fixed;
	top: 0;
	left: 0;

	@media only screen and (max-width: 767px) {
		min-width: 200px;
		margin-top:  87px;
        min-height: 0;
        padding-bottom: 20px;
        margin-left: 0;
	}
	
	padding: 50px 0 35px 57px;
	
	&__inside{
		top: 0;
		position: relative;
		
		.navigation{
		
			>li{
				padding-bottom: 20px;
				font-size: 16px;
				
				@media only screen and (min-width: 1201px){
					font-size: 18px;
				}
				
				a{
					color: #a0c0bc;
					position: relative;
					
				}
				
				a:focus, a:hover{
					color: #fff;
					text-decoration: none;
					
					&:before{
						content: '';
						background: transparent;
						border: 2px solid #fff;
						border-radius: 50%;
						width: 8px;
						height: 8px;
						display: inline-block;
						position: absolute;
						left: -15px;
						margin-top: 8px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width: 767px) {
		padding: 50px 10px 35px 35px;
	}
}