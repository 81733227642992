﻿.slide {
    min-height: calc(100vh - 308px);
	width: 100%;
	background-size: cover;
	
	@media only screen and (max-width: 640px) {
		min-height: 189px;
	}
}

.slide1 {
    background: url(../media/slide1-notext.png) center center;
    background-size: cover;

    @media only screen and (max-width: 460px) {
        background-position: 40% center;
    }

    + .carousel-caption {
        display: flex !important;
        height: 100%;
        @include align-items(center);
        text-align: right;
        right: 15%;

        @media only screen and (max-width: 460px) {
            right: 5%;
            left: 13%;
            height: 60%;
            top: 0;
        }

        p {
            width: 100%;
            color: #655e56;
            font-size: 22.4px;
            text-shadow: none;

            @media only screen and (min-width: 1201px) {
                //font-size: 24px;
                font-size: 1.88vw;
            }

            @media only screen and (max-width: 991px) {
                font-size: 2.3vw;
            }

            @media only screen and (max-width: 460px) {
                font-size: 8.5px;
            }


            span {
                display: block;
                font-size: 24px;
                text-transform: uppercase;
                font-weight: 700;

                @media only screen and (min-width: 1201px) {
                    //font-size: 28px;
                    font-size: 2vw;
                }

                @media only screen and (max-width: 991px) {
                    font-size: 2.45vw;
                }

                @media only screen and (max-width: 460px) {
                    font-size: 9px;
                }
            }
        }
    }
}

.slide2 {
	background: url(../media/slide2-notext.png) center center;
	background-size: cover;
	
	+ .carousel-caption{
	    display: flex!important;
		text-align: left;
		width: 100%;
		left: 0;
		height: 100%;
		align-items: center;
		
		h3{	
			width: 100%;
			color: #fff;
			font-size: 36px;
			text-shadow: none;
			padding: 10px 55px 40px;
			font-weight: 700;
			font-size: 36px;
			line-height: 1.2;
			text-align: center;
			text-transform: uppercase;
			
			@media only screen and (min-width: 1170px) {
				font-size: 3vw;
				padding: 0 55px 4vw;
				line-height: 1.12;
			}
			
			@media only screen and (max-width: 991px) {
				font-size: 30px;
				padding: 10px 55px 30px;
				font-size: 4.15vw;
			}
			
			@media only screen and (max-width: 768px) {
				font-size: 2.59vw;
				padding: 10px 55px;
			}
			
			@media only screen and (max-width: 640px) {
				font-size: 16px;
				margin-top: 10px;
				padding: 10px 15px;
			}	
			
			span{
				display: block;
				font-size: 25px;
				font-weight: 400;
				
				@media only screen and (min-width: 1170px) {
					font-size: 2.1vw;
				}

				@media only screen and (max-width: 991px) {
					font-size: 21px;
					font-size: 2.9vw;
				}
				
				@media only screen and (max-width: 768px) {
					font-size: 1.8vw;
				}
				
				@media only screen and (max-width: 640px) {
					font-size: 11.3px;
				}	
				
				+ span{
					font-size: 22.5px;
					font-weight: 700;
					
					@media only screen and (min-width: 1170px) {
						font-size: 1.88vw;
					}
					
					@media only screen and (max-width: 991px) {
						font-size: 18.8px;
						font-size: 2.59vw;
					}
					
					@media only screen and (max-width: 768px) {
						font-size: 1.63vw;
					}
					
					@media only screen and (max-width: 640px) {
						font-size: 10px;
					}	
				}				
			}
		}
	}
}

.slide3 {

    background: url(../media/slide3-notext.png) center center;
	background-size: cover;
	
		&+ .carousel-caption{
	    display: flex!important;
		text-align: left;
		width: 45%;
		left: 55%;
		height: 100%;
		
		h3{	
			width: 100%;
			color: #fff;
			font-size: 22.4px;
			text-shadow: none;
			background: #000;
			padding: 20px 55px;
			font-weight: 700;
			font-size: 35px;
			line-height: 1.4;
			margin: 2vw auto auto;
			
			@media only screen and (min-width: 992px) and (max-width: 1200px) {
				font-size: 3vw;
			}
			
			@media only screen and (max-width: 991px) {
				font-size: 2.3vw;
				margin: 4vw auto auto;
				padding: 2vw 5vw;
			}
			
			@media only screen and (max-width: 320px) {
				font-size: 11px;
				margin-top: 20px;
				padding: 10px 15px;
			}	
			
			span{
				display: block;
				font-size: 24px;
				text-transform: uppercase;
				font-weight: 700;
				
				@media only screen and (max-width: 991px) {
					font-size: 2.5vw;
				}
				
			}
		}
	}
}

.slide4 {
    background: url(../media/slide4-notext.png) center center;
    background-size: cover;

    + .carousel-caption {
        display: flex !important;
        height: 60%;
        @include align-items(center);
        text-align: right;
        right: 10%;
        top: 0;

        @media only screen and (max-width: 460px) {
            right: 13%;
            left: 13%;
        }

        @media only screen and (max-width: 640px) {
            height: 20%;
        }

        @media only screen and (min-width: 641px) {
            right: 2%;
        }

        @media only screen and (min-width: 768px) {
            right: 5%;
        }

        @media only screen and (min-width: 992px) {
            right: 5%;
        }

        @media only screen and (min-width: 1201px) {
            right: 15%;
        }

        @media only screen and (min-width: 1500px) {
            right: 18%;
        }

        @media only screen and (max-width: 767px) {
            right: 10%;
        }

        p {
            width: 100%;
            color: #ffffff;
            font-size: 22.4px;
            text-shadow: none;

            @media only screen and (min-width: 992px) {
                font-size: 1.4vw;
            }

            @media only screen and (min-width: 1201px) {
                //font-size: 24px;
                font-size: 1.04vw;
            }

            @media only screen and (max-width: 991px) {
                font-size: 1.3vw;
            }

            @media only screen and (max-width: 640px) {
                font-size: 10px;
            }

            span {
                display: block;
                font-size: 24px;
                text-transform: uppercase;
                font-weight: 700;

                @media only screen and (min-width: 768px) {
                    font-size: 1.5vw;
                }

                @media only screen and (min-width: 992px) {
                    font-size: 1.62vw;
                }

                @media only screen and (min-width: 1201px) {
                    //font-size: 28px;
                    font-size: 1.2vw;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 1.5vw;
                }

                @media only screen and (max-width: 640px) {
                    font-size: 11.5px;
                }

            }
        }
    }
}

.slide5 {
    background: url(../media/slide5-notext.png) center center;
    background-size: cover;

    + .carousel-caption {
        display: flex !important;
        height: 60%;
        text-align: left;
        top: 0;

        @media only screen and (max-width: 460px) {
            left: 2%;
            padding-top: 7px;
        }

        @media only screen and (max-width: 640px) {
            padding-top: 8px;
        }

        @media only screen and (min-width: 641px) {
            left: 5%;
        }

        @media only screen and (min-width: 1500px) {
            left: 15%;
        }

        p {
            width: 100%;
            color: #ffffff;
            font-size: 1.8vw;
            text-shadow: none;

            @media only screen and (max-width: 460px) {
                font-size: 8.5px;
            }

            @media only screen and (min-width: 641px) {
                font-size: 2.2vw;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 1.7vw;
            }
        }
    }
}

.carousel-control-next-icon{
	background: url('../media/slide-next.png') no-repeat;
	height: 39px;
}

.carousel-control-prev-icon{
	background: url('../media/slide-prev.png') no-repeat;
	height: 39px;
}