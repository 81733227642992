.basket {

    h1 {
        text-transform: uppercase;
        font-size: 26px;
    }

    display: flex;

    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
    }

    &-main {
        background: #dad2ca;
        padding: 30px 30px 80px;
        min-height: 48vw;
        width: 100%;

        @media only screen and (min-width: 768px) {
            width: 68%;
            padding-right: 100px;
        }

        .row {
            margin: 0 -15px;

            > div {
                padding: 0 15px;
            }
        }

        input, textarea {
            font-size: 16px;
            padding: 12px 8px;
            width: 100%;
            border: 1px solid #b7b7b7;
            margin-bottom: 15px;
            font-family: 'PT Sans', sans-serif;
        }

        textarea {
            min-height: 75px;
        }
    }

    &-sidebar {
        background: #efebe8;
        padding: 45px 30px;
        width: 100%;

        @media only screen and (min-width: 768px) {
            width: 40%;
        }
    }

    &__purchase {
        padding-top: 25px;
        border-bottom: 1px solid #b7b7b7;

        &:first-child {
            padding-top: 0;
        }

        &-img {
            width: 100%;
            border: 1px solid #b7b7b7;
            max-width: 66px;
            max-height: 66px;
        }

        &-name {
            line-height: 1.2;
        }

        &-calc {
            display: flex;
            width: 100%;
            @include align-items(center);

            > div {
                justify-content: center;
                width: 25%;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                    text-align: center;
                }
            }

            .count-input {
                border: 1px solid #b7b7b7;
                line-height: 2;
            }

            padding: 10px 0;
        }

        &-fraction {
            line-height: 1.2;
        }

        &-delete {
            text-align: right;
            color: #b7b7b7;
            font-size: 11px;
            cursor: pointer;
            margin-bottom: 8px;

            &:hover {
                color: #000;
            }
        }
    }

    &__order {
        &-details {
            padding: 20px 0;
            border-bottom: 1px solid #b7b7b7;
            text-transform: uppercase;
            color: #b7b7b7;
            text-align: right;

            span {
                color: #5b5c5c;
            }

            &_total {
                border-bottom: 0;
            }
        }

        &-sum {
            margin-bottom: 35px;

            &_confirm {
                margin-bottom: 20px;
            }
        }
    }

    &__breadcrumbs {
        font-size: 11px;
        color: #808080;
        margin-top: 10px;

        a {
            color: #808080;

            &.current {
                color: #000;
            }
        }
    }

    &__button-next {
        font-weight: 700;
        background: #3f4c6b;
        color: #fff;
        border: 0;
        padding: 11px 20px;
        font-size: 18px;

        &:hover {
            padding: 8px 17px;
        }
    }

    &__newsletter-subscription {
        color: #5b5c5c;
    }

    &__agree-oferta {
        color: #5b5c5c;
        margin-top: 20px;

        span {
            border-bottom: 1px solid #5b5c5c;
        }
    }

    &_mob-reverse {
        @media only screen and (max-width: 767px) {
            @include flex-direction(column-reverse);
        }
    }
}

.ordering{
    &__presonal-data{
		margin-top: 30px;
    }
	
	&__delivery{
			
        h4{
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 20px;
        }
		
		&-address{
            margin-top: 40px;
		}
		
		&-methods{
			padding-top: 50px;
            margin-bottom: 40px;

            span{
                display: block;
            }
		}
	}
	
	&__footnote{
		color: #5b5c5c;
		padding-bottom: 5px;
		border-bottom: 2px solid #b7b7b7;
	}
	
	h3{
		font-size: 18px;
		margin-bottom: 20px;
	}
	
	&__description{
		font-size: 14px;
		color: #afafaf;
		
		.ordering &{
			font-size: 16px;
			color: #787879;
		}
	}
	
	&__option{
		padding-left: 30px;
		font-size: 14px;
		
		.ordering &{
			font-size: 16px;
		}
		
		*{
		    line-height: 1.2;
		}
		
		label:before{
			position: absolute;
			left: -30px;
			top: 4px;
		}
		
		a{
			border-bottom: 1px solid #afafaf;
		}
	}
}

.way-of-pay{
	padding: 20px 0;
	&__item{
		margin: 20px 0;
		
		&:first-child{
			margin-top: 0;
		}
		
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.delivery-method{
    font-size: 16px;
    color: #787879;

    label{
        margin-bottom: 0;

        span:first-child{
            color: #1e2e45;
        }
    }

    input + label:before{
        content: '';
        position: absolute;
        left: -30px;
        top: 4px;
    }

    input:checked + label{
        &:before{
            content: '';
            background: #051831!important;
            box-shadow: 0px 0px 0px 1px #051831!important;
        }

        span:first-child{
            font-weight: 700;
            color: #000!important;
        }
    }

    &__note{
        font-size: 11px;
    }

    &__price{
        label span:first-child{
            color: #000;
        }
    }

    > div{
        margin-bottom: 20px;
    }

    &-container{
        padding-left: 30px;
    }
}

.border-bottom{
	border-bottom: 2px solid #b7b7b7;
}

.confirmation{

	&-info{
		font-size: 16px;
		margin-top: 20px;
		
		p{
			margin-top: 20px;
			
			&.last{
				margin-top: 30px;
			}
		}
	}

	&-purchase{
		&-list{
			list-style-type: none;
			counter-reset: num;
			margin-bottom: 20px;
			
			li{
				margin-bottom: 15px;
				p{
					line-height: 1.2;
				}
			}
		   li::before {
			content: counter(num) '. '; /* Выводим число */
			counter-increment: num; /* Увеличиваем значение счётчика */
			font-weight: 700;
		   }  
		}
		
		&-price{
			@include display(flex);
			@include justify-content(space-between);
			
			span:last-child{
				padding-right: 20px;
				color: #5b5c5c;
			}
		}
	}
	
	&-total-price{
		font-size: 18px;
		font-weight: 700;
		color: #000;
	}
	
	&-button-cover{
		margin-top: 130px;
		text-align: right;
	}
}

.step-back{
	margin-top: 60px;
	text-align: right;
		@media only screen and (min-width: 768px) {
            padding-right: 110px;
        }
	
	a{
		color: #000;
	}
}