﻿$color-blue: #3f4c6b;

body {
    font-family: 'PT Sans', sans-serif;
	color: #000;
    font-size: 14px;
    line-height: normal;
}

*{
    font-family: 'PT Sans', sans-serif;
}

a {
  outline: none;
  text-decoration: none;
  
	&:hover, &:focus{
		text-decoration: none;
	}
}

p{
	line-height: normal;
	margin-bottom: 0;
}

.wrapper{
	min-width: 320px;
}


.main-content {
    margin-left: 83px;
	margin-top: 97px;
    min-height: 48vw;
	
	@media only screen and (max-width: 767px) {
		margin-top: 87px;
        margin-left: 0px;
	}
}

.jumping-block {
    background-color: rgba(171, 201, 197, 0.5);
    position: relative;
    display: inline-block;
	vertical-align: top;
	position: relative;
	top: 0;
    padding: 20px 20px 0;
	width: 100%;
    color: #000;
	
	&:hover, &:focus, &.active{
	    color: #000;
		@media only screen and (min-width: 641px) {
			margin-top: -30px;
			padding-top: 50px;
		}
	}
	
	&:first-child{
		@media only screen and (min-width: 641px) and (max-width: 767px) {
			margin-right: 15px;
		}
	}

    .row{
		flex-wrap: nowrap;
		
		> div{
			padding: 0 3px;
			
			
			@media only screen and (min-width: 461px) {
				padding: 0 5px;
			}
			
			&:nth-child(3){
				max-width: 65px;

                @media only screen and (min-width: 768px) and (max-width: 991px){
                    max-width: 45px;
                    padding: 0;
			    }
			}
			
			&:nth-child(4){
                flex-basis: calc(25% + 20px);
                max-width: calc(25% + 20px);
				text-align: right;

                @media only screen and (max-width: 460px){
                    flex-basis: calc(20% + 20px);
                    max-width: calc(20% + 20px);
				}
				
				@media only screen and (min-width: 461px) and (max-width: 767px){
					flex-basis: 25%;
					max-width: 25%;
				}

                @media only screen and (min-width: 768px) and (max-width: 991px){
					flex-basis: 30%;
					max-width: 30%;
				}
			}
		}
	} 
	
	@media only screen and (min-width: 768px) {
		width: calc(50% - 58.5px);
	}
	
	@media only screen and (min-width: 641px) and (max-width: 767px) {
		width: calc(50% - 15px);
	}
}

.jumping-block:last-child {
	background-color: rgba(214, 229, 203, 0.5);
	
	.purchase-dropdown-block{
		@include flex-direction(row-reverse);
	}
	
	.purchase-calc {
		background: rgba(255,255,255,0.5);
		padding: 30px 0 20px;
		
		@media only screen and (min-width: 641px) {
			width: calc(100% - 19px);
			margin-right: 10px;
			margin-left: 30px;
			margin-bottom: 30px;
			padding-top: 40px;
		}
	}
	
	.purchase-calc-text{
		
		@media only screen and (min-width: 641px) {
			margin-left: calc(-100% - 90px);
			margin-right: auto;
			background: rgba(214,229,203,0.5);
            width: calc(100% + 71px);
		}

        @media only screen and (min-width: 768px){
			width: calc(100% - 9px);
            margin-left: calc(-100% - 10px);
		}
	}

	@media only screen and (min-width: 768px) {
		width: calc(50% + 24.5px);
		margin-left: 30px;
	}
	
	@media only screen and (min-width: 641px) and (max-width: 767px) {
		margin-left: 11px;
	}
}

.purchase{
	padding-bottom: 20px;
	cursor: pointer;
	@include display(flex);
	@include justify-content(space-between);
	
	@media only screen and (min-width: 768px){
		@include justify-content(space-around);
	}    
	
	&-dropdown-block{
		width: 100%;
		margin-top: 30px;
		display: none;
		
		@media only screen and (max-width: 640px){
			flex-wrap: wrap;
		}    
		
		.open &{
			@include display(flex);
		}
	}
	
	&-text{
		position: relative;
	}
	
	&-subtitle{
		text-transform: uppercase;
		
		@media only screen and (min-width: 1201px){
			font-size: 16px;
		}
		
		@media only screen and (min-width: 641px) and (max-width: 1200px){
			font-size: 1vw;
		}

        @media only screen and (min-width: 320px) and (max-width: 460px){
			font-size: 3vw;
		}

        @media only screen and (max-width: 319px){
			font-size: 9px;
		}
            
	}
	
	&-title{
		text-transform: uppercase;
		border-bottom: 1px solid #fff;
		font-size: 27px;
		font-weight: 700;
		padding-bottom: 15px;
		margin-bottom: 10px;
		
		@media only screen and (min-width: 1201px){
			font-size: 29px;
		}
		
		@media only screen and (min-width: 641px) and (max-width: 1200px){
			font-size: 2vw;
		}

        @media only screen and (min-width: 320px) and (max-width: 460px){
			font-size: 5vw;
		}

        @media only screen and (max-width: 319px){
			font-size: 17px;
		}
	}
	
	&-amount{
		
		@media only screen and (min-width: 1201px){
			font-size: 16px;
		}
	
		@media only screen and (min-width: 641px) and (max-width: 991px){
			font-size: 12px;
		}

        @media only screen and (min-width: 320px) and (max-width: 460px){
			font-size: 3.5vw;
		}

        @media only screen and (max-width: 319px){
			font-size: 10px;
		}
	}
	
	img{
		@media only screen and (min-width: 641px) and (max-width: 1200px){
			width: 12vw;
		}

        @media only screen and (max-width: 460px){
			width: 24vw;
		}

         @media only screen and (max-width: 319px){
			min-width: 70px;
		}
	}
	
	&-arrow-down{
		background: url('../media/arrow-circle-down-white-20.png') no-repeat;
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0;
		bottom: 0;

	}
	
	&-calc{
		background: rgba(255,255,255,0.5);
		padding: 30px 0 20px;
		width: 100%;
		
		@media only screen and (min-width: 641px){
			width: calc(100% - 20px);
			margin: -30px 30px 30px 10px;
			padding-top: 40px;
		}
		
		@media only screen and (min-width: 641px) and (max-width: 991px){
			font-size: 10px;
		}

		&__table{
			max-width: 450px;
			margin: 0 auto;
			
			@media only screen and (max-width: 460px){
				font-size: 12px;
			}
		}
		
		.row{
			margin: 0px;
			margin-bottom: 10px;
		}
		
		&__amount{
			background: #fff;
			text-align: center;
			padding: 3px 0;
			max-width: 65px;
			
			&:before{
				content: '-';
				float: left;
				margin-left: 5px;
				cursor: pointer;
				color: #a5a5a5;
			}
			
			&:after{
				content: '+';
				float: right;
				margin-right: 5px;
				cursor: pointer;
				color: #a5a5a5;
			}
		}
		
		&-text{
			padding: 20px 0;
			
			@media only screen and (min-width: 1201px){
				font-size: 16px;
			}
			
			@media only screen and (min-width: 641px){
				width: calc(100% + 71px);
				margin-right: calc(-100% - 200px);
				background-color: rgba(171,201,197,0.5);
				padding: 20px;
			}
			
			@media only screen and (min-width: 768px){
				width: calc(100% + 157px);
			}
		}
		
		button{
			background-color: $color-blue;
			border: 0;
			border-radius: 0;
			padding: 4px;
			color: #fff;
            font-weight: 700;

            &:hover{
                padding: 1px;
                font-weight: 700;
            }

			@media only screen and (min-width: 461px){
				padding: 4px 14px;
				font-weight: 700;

                &:hover{
                    padding: 1px 11px;
                }
			}

            @media only screen and (min-width: 461px) and (max-width: 767px){
				padding: 4px 7px;
				font-weight: 700;

                &:hover{
                    padding: 1px 4px;
                }
			}

            @media only screen and (min-width: 768px)  and (max-width: 991px){
				padding: 4px 3px;
				font-weight: 700;

                &:hover{
                    padding: 1px 0px;
                }
			}

            @media only screen and (min-width: 992px)  and (max-width: 1200px){
				padding: 4px 11px;
				font-weight: 700;

                &:hover{
                    padding: 1px 8px;
                }
			}
		}
	}
}

.content {
    padding: 70px 30px;
    font-size: 14px;
	
	@media only screen and (min-width: 1201px){
		font-size: 16px;
	}

    .title {
        display: block;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 1.5;
    }

    p {
        margin-top: 20px;
    }

    &-about-page{
        padding-top: 0;
    }
	
	&__center{
		text-align: center;
		
		@media only screen and (min-width: 767px) {
			padding-right: 113px!important;
		}
	}

    &-hidden{
        margin-top: 20px;
    }
}

.detailed {
    color: #c2d8d5;
    border-bottom: 1px solid #c2d8d5;
    display: inline-block;
    cursor: pointer;
}

.detailed-block {
    margin-top: 15px;
    text-align: center;
}

.flexbox{
    @include display(flex);

    > div{
       width: calc(50% + 25px);
       &:first-child{
           width: calc(50% - 25px);
			-webkit-box-orient: vertical!important;
			-webkit-box-direction: normal!important;
			-webkit-flex-direction: column!important;
			-ms-flex-direction: column!important;
			flex-direction: column!important;
       }
    }
}

/* ----- tabs ------ */

.tabs-wrapper{
    position: relative;
  
	> ul{
		width: calc(50% - 25px);
		display: flex;
		flex-wrap: wrap;
        border-bottom: 0;
		margin-bottom: 0;
		height: 380px;
		
		
		li{
            border-radius: 0;
            margin-bottom: 1px;
            text-transform: uppercase;
            position: relative;
			width: 100%;
			float: none;
			
			a{
			   /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c6dad6+0,d7e4e2+100 */
				background: rgb(198,218,214); /* Old browsers */
				background: -moz-linear-gradient(left, rgba(198,218,214,1) 0%, rgba(215,228,226,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(198,218,214,1) 0%,rgba(215,228,226,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(198,218,214,1) 0%,rgba(215,228,226,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6dad6', endColorstr='#d7e4e2',GradientType=1 ); /* IE6-9 */
				padding: 30px 32px;
				height: 100%;
				display: flex;
				align-items: center;
				color: #000;
				font-size: 24px;
                border: 0;
                border-radius: 0;
				
				@media only screen and (min-width: 1201px){
					font-size: 26px;
				}
				
				@media only screen and (min-width: 992px){
					padding-left: 65px;
					font-size: 24px;
				}

				&:after{
					content: '';
					background: url('../media/arrow-circle-right-white-32.png') no-repeat;
					line-height: normal;
					width: 32px;
					height: 32px;
					position: absolute;
					right: 32px;
					top: 50%;
					margin-top: -16px;
			   }
			   
                &:hover, &:focus{
                    color: #fff;
                }

                &.active{
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#66bfca+0,d5e0dc+100 */
					background: #66bfca; /* Old browsers */
					background: -moz-linear-gradient(left, #66bfca 0%, #d5e0dc 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, #66bfca 0%,#d5e0dc 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, #66bfca 0%,#d5e0dc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66bfca', endColorstr='#d5e0dc',GradientType=1 ); /* IE6-9 */
					color: #fff;
					
					&:after{
						content: none;
					}
			    }
			}
		}
	}
	
	@media only screen and (max-width: 767px){
		@include flex-wrap(wrap);
	}
	
    .panel{
        border: none;
        box-shadow: none;
        margin-top: 1px!important;
        box-shadow: none;

        &-group{
			width: 100%;
	    }
		
		&-title{
			font-weight: 400;
		}

        &-heading{
            background-color: transparent!important;
            border: none;
            padding: 0;

            a{
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c6dad6+0,d7e4e2+100 */
				background: rgb(198,218,214); /* Old browsers */
				background: -moz-linear-gradient(left, rgba(198,218,214,1) 0%, rgba(215,228,226,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(198,218,214,1) 0%,rgba(215,228,226,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(198,218,214,1) 0%,rgba(215,228,226,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6dad6', endColorstr='#d7e4e2',GradientType=1 ); /* IE6-9 */
				padding: 30px 32px;
				height: 100%;
				align-items: center;
				color: #000;
				font-size: 24px;
                border: 0;
                border-radius: 0;
                width: 100%;
                display: flex;
                text-decoration: none;
                position: relative;
				
                &:after{
					content: '';
					background: url('../media/arrow-circle-right-white-32.png') no-repeat;
					line-height: normal;
					width: 32px;
					height: 32px;
					position: absolute;
					right: 32px;
					top: 50%;
					margin-top: -16px;
					
                    @media screen and (max-width: 460px){
						content: '';
                        background: url('../media/arrow-circle-down-white-20.png') no-repeat;
                        width: 20px;
                        height: 20px;
                        margin-top: -10px;
                    }
			   }

                &:not(.collapsed){
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#66bfca+0,d5e0dc+100 */
					background: #66bfca; /* Old browsers */
					background: -moz-linear-gradient(left, #66bfca 0%, #d5e0dc 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, #66bfca 0%,#d5e0dc 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, #66bfca 0%,#d5e0dc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66bfca', endColorstr='#d5e0dc',GradientType=1 ); /* IE6-9 */
					color: #fff;
			    }

                @media screen and (max-width: 460px){
                    padding: 10px;
                    font-size: 18px;
                }
            }
        }
	}
}

.tab-content{
	@media only screen and (min-width: 1201px){
		font-size: 16px;
	}
}

.tab-pane{
	padding: 0 20px;
}

#default_img{
	padding: 0;
	height: 380px;
    overflow: hidden;
}

/* ---- content block ----  */

.content-block{
    &__title{
        margin-top: 45px;
        text-align: center;
        position: relative;
        margin-bottom: 20px;

        hr{
            margin-top: 0px;
            margin-bottom: 0px;
            border: 0;
            border-top: 2px solid #e2edeb;
            position: absolute;
            top: 52%;
            width: 100%;
            z-index: 36;
        }

        h2{
            min-height: 40px;
            background-color: #fff;
            line-height: 36px;
            font-size: 24px;
            text-align: center;
            color: #000000;
            position: relative;
            display: inline-block;
            padding: 15px 30px;
            z-index: 37;
			
			@media only screen and (min-width: 1201px){
				font-size: 26px;
			}
        }
    }

    &__item{
        width: calc(50% + 60px);
        padding: 0px 15px 15px;
        position: relative;
		color: #5c5c5c;
		
		@media only screen and (min-width: 1201px){
			font-size: 16px;
		}

			&:nth-child(odd){
			  width: calc(50% - 25px);
			}

          &-detailed{
				margin-top: 25px;
				
               &:before{
                    content: '';
                    background: url('../media/dropdown-arrow.png');
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 7px;
              }

               &:hover{
                   opacity: 0.7;
                   cursor: pointer;
               }
          }
		  
		  &-roll-up{
				margin-top: 25px;
				display: none;
			  
               &:before{
                    content: '';
                    background: url('../media/slide-up-arrow.png');
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 7px;
              }

               &:hover{
                   opacity: 0.7;
                   cursor: pointer;
               }
		  }

          &-vodka{
              background: url('./../media/vodka-cucumbers.jpg') 50% 50% no-repeat;
              background-size: cover;
              min-height: 200px;
          }

          &-coconut{
              background: url('./../media/coconuts-water-spray.jpg') 50% 50% no-repeat;
              background-size: cover;
              min-height: 200px;
          }
		  
		@media only screen and (max-width: 767px) {
			width: 100%;
			padding-bottom: 30px;
			position: relative;

			  &:nth-child(odd){
				  width: 100%;
			  }
		}
		
		&-img{
			  width: calc(50% + 45px);
			  margin-left: 15px;
			  margin-bottom: 15px;
			  padding: 0;
			  
				@media only screen and (max-width: 767px){
					width: calc(100% - 30px);
					margin-right: 30px;
					padding: 15px;
				}   
		}
    }

    &__content{
        @include display(flex);
		padding-top: 15px;
		
		
		@media only screen and (max-width: 767px){
		padding: 15px;
			@include flex-wrap(wrap);
			@include flex-direction(column-reverse);
		}
    }
}

.mt15{
    margin-top: 15px;
}

.moonshiner{
    &-block{
        background-color: #ebebeb;
        padding: 70px 30px;

        &-title{
            span{
                font-weight: 700;
                text-transform: uppercase;
                font-size: 24px;
            }
        }

        &-content{
          padding: 0;
          margin: 0;
          list-style: none;
  
          @include display(flex);
		  
          -webkit-flex-flow: row wrap;
          @include justify-content(start);
		  
		  &_list{
			margin: 0 0 0 -30px;
		  }
        }

        &-footer{
            padding-top: 40px;
        }

    }

    &__item{
        margin-top: 50px;
        max-width: 262px;
        display: block;
        margin-left: 30px;
		
		@media screen and ( min-width: 1590px) and ( max-width: 1800px){
			&:not(:nth-child(-n+5)){
				display: none;
			}
		}
		
		@media screen and ( min-width: 1300px) and ( max-width: 1589px){
			&:not(:nth-child(-n+4)){
				display: none;
			}
		}
		
		@media screen and ( min-width: 1010px) and ( max-width: 1299px){
			&:not(:nth-child(-n+3)){
				display: none;
			}
		}
		
		@media screen and ( min-width: 641px) and ( max-width: 1009px){
			&:not(:nth-child(-n+2)){
				display: none;
			}
		}
		
		@media screen and ( max-width: 640px){
			&:not(:nth-child(-n+1)){
				display: none;
			}
		}
		
		/*
		&:not(:nth-child(n+2)){
			@media screen and ( max-width: 991px){
				display: none;
			}
		}
		
		&:not(:first-child){
			@media screen and ( max-width: 600px){
				display: none;
			}
		}*/

        &-title{
            margin: 25px 0 15px;
            font-size: 16px;
            font-weight: 700;
        }

        a{
            color: #acc9c6;
            margin-top: 15px;
            display: inline-block;
            border-bottom: 1px solid;
        }
    }

    &__all-articles{
        display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
        padding: 8px 15px;
        background: #3f4c6b;
        color: #fff;
		
		&:hover, &:focus{
		    border: 3px solid #3f4c6b;
            background: #b9d7d7;
            color: #3f4c6b;  
            padding: 5px 12px;
		}
    }
	
	&__full-article{
		margin-top: 45px;
		min-height: 40vw;
		
		@media screen and ( min-width: 992px){
			@include display(flex);
		}
		
		
		&-title{
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 15px;
		}
		
		&-img{
			
			margin-top: 30px;
			
			@media screen and ( min-width: 992px){
				min-width: 500px;
				margin-left: 30px;
				margin-top: 0;
			}
			
			
			img{
				padding-bottom: 30px;
			}
		}
	}
}

h4{
    font-size: 16px;
    font-weight: 700;
}

input.checkbox-default[type="checkbox"], input.radio-default[type="radio"] {
    display: none;

    & ~ label {
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        position: relative;
        width: auto;
        height: auto;
        line-height: normal;
        display: inline-block;
        cursor: pointer;

        &:before {
            content: '';
            background-image: none;
            width: 11px;
            height: 11px;
            border: 1px solid #ffffff;
            background-color: transparent;
            border-radius: 50%;
            margin-left: 0;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            box-shadow: 0px 0px 0px 1px #b8b9b9;
        }
    }

    &:checked ~ label {
        &:before {
            content: '';
            background-color: #000;
             box-shadow: 0px 0px 0px 1px #000;
        }
    }
}

.close{
	position: absolute;
	right: 10px;
	top: 5px;
}

.modal-body{
	padding: 20px 50px;
    text-align: center;
	font-size: 18px;
}

.modal-dialog{
	max-width: 940px;
}

.modal-content{
	padding-top: 50px;
	min-height: 300px;
}

/*  helpfull classes */

.not-empty{
    span{
        font-weight: 700;
    }
}

.bold{
	font-weight: 700;
}

strong {
    font-weight: 700;
}

.ui-helper-hidden-accessible {
    display: none;
}

button{
    &:focus{
        outline: none;
    }
    &:not(.close){
        &:hover{
            border: 3px solid #3f4c6b;
            background: #b9d7d7;
            color: #3f4c6b;
            cursor: pointer;
        }
    }
}

.count-input {
  position: relative;
  width: 100%;
  max-width: 165px;
  background: #fff;
  max-width: 65px;
  font-family: 'PT Sans', Arial, sans-serif;
  
  a{
	color: #a5a5a5;
	
	&:hover{
		color: #000000;
	}
  }
}
.count-input input {
  width: 100%;
  border-radius: 2px;
  background: none;
  text-align: center;
  line-height: 1.6;
font-family: 'PT Sans', Arial, sans-serif;
}
.count-input input:focus {
  outline: none;
}
.count-input .incr-btn {
  display: block;
  position: absolute;
  width: 18px;
  height: 24px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  line-height: 22px;
  top: 50%;
  right: 0;
  margin-top: -11px;
  text-decoration:none;
  font-family: 'PT Sans', Arial, sans-serif;
}
.count-input .incr-btn:first-child {
  right: auto;
  left: 0;
  top: 50%;
}
.count-input.count-input-sm {
  max-width: 125px;
}
.count-input.count-input-sm input {
  height: 36px;
}
.count-input.count-input-lg {
  max-width: 200px;
}
.count-input.count-input-lg input {
  height: 70px;
  border-radius: 3px;
}

.hidden-anchor{
	position: absolute;
    top: -97px;
}